@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-100px);
  }
}

.floating-text {
  position: absolute;
  animation: floatUp 1s ease forwards;
  pointer-events: none;
}

.drx-particle {
  width: 30px;
  position: absolute;
  top: -30px;
  left: -30px;
  pointer-events: none;
  animation: drx-up 2s linear forwards;
}

@keyframes drx-up {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    top: -100px;
  }
}

.claim-btn {
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  padding: 1rem 2rem;
}

.claim-btn::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -70%;
  top: -90%;
  padding: 2rem;
  width: 200%;
  height: 200%;
  background-color: #1a232a;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    transparent,
    rgba(168, 239, 255, 1),
    transparent 30%
  );
  animation: rotate 1s linear infinite;
}

.claim-btn::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 5px;
  top: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  background: #000;
  border-radius: 50px;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.letters-container {
  display: block;
  height: 2rem;
  overflow: hidden;
}

.letter {
  position: relative;
  display: inline-block;
  height: 2rem;
  line-height: 2rem;
}

.letter::after {
  position: absolute;
  left: 0;
  top: 100%;
  height: 2rem;
  content: attr(data-letter);
}

.sparkles {
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: block;
}

.sparkle {
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 0;
}
