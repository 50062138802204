.pulse {
    animation: pulse-animation 1s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px #e0b76190;
    }
    100% {
      box-shadow: 0 0 0 30px #e0b76100;
    }
  }
  