/* .pulse {
  position: relative;
  transition: all 0.3s ease-in-out;
  border-radius: 100%;
  cursor: pointer;
  color: #ffff;
  outline: none;
  overflow: hidden;
  animation: pulse-animation 1s infinite;
}

.pulse::before {
  animation: shine 1s ease-out infinite;
  content: "";
  position: absolute;
  width: 250px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px #e0b76190;
  }
  100% {
    box-shadow: 0 0 0 20px #e0b76100;
  }
} */

.pulse {
	position: relative;
	background: #000;
  border-radius: 50px;
}

.pulse:before, .pulse:after {
	content: '';
	position: absolute;
	left: -2px;
	top: -2px;
  border-radius: 50px;
	background: linear-gradient(45deg, #fb0094, #0000ff, #00ff00,#ffff00, #ff0000, #fb0094, 
		#0000ff, #00ff00,#ffff00, #ff0000);
	background-size: 400%;
	width: calc(100% + 4px);
	height: calc(100% + 4px);
	z-index: -1;
	animation: steam 20s linear infinite;
}

@keyframes steam {
	0% {
		background-position: 0 0;
	}
	50% {
		background-position: 400% 0;
	}
	100% {
		background-position: 0 0;
	}
}

.pulse:after {
	filter: blur(50px);
}